<template>
    <div class="card-tail card-f-h" v-loading="$waiting.is('loading')">
        <ModalAdd ref="modalAdd" :visible="modalAddVisible" :selectable-financial-years="selectableFinancialYears" :client-id="clientId" @close="modalAddVisible = false" />
        <div class="flex justify-between border-b mb-3 pb-3">
            <el-button type="primary" @click="showModalAdd">Skapa ny årsredovisning</el-button>
        </div>
        <Table :table-data="annualReportsData" @edit="showModalEdit" @deleteModal="showConfirmDeleteModal" />
        <el-dialog title="Bekräfta borttagning" :visible.sync="modalDeleteVisible" width="30%" @close="closeModalDelete">
            <span>Är du säker på att du vill radera denna årsredovisning?</span>
            <span slot="footer" class="dialog-footer">
                <el-button ref="cancelSelect" type="warning" @click="closeModalDelete">Avbryt</el-button>
                <el-button type="success" @click="handleDelete">Radera</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Api from "../annualReport.api";

export default {
    components: {
        Table: () => import("./AnnualReportStartTable.vue"),
        ModalAdd: () => import("./AnnualReportStartModalAdd.vue"),
    },
    data() {
        return {
            clientId: "",
            annualReportsData: [],
            selectableFinancialYears: [],
            modalAddVisible: false,
            selectedItemId: null,
            modalEditVisible: false,
            modalDeleteVisible: false,
            annualReportId: null,
        };
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Årsredovisning NY");
        document.title = "Årsredovisning NY";
    },

    async created() {
        this.$waiting.start("loading");
        await this.getAnnualReportsAndFinancialYears();
        this.$waiting.end("loading");
    },

    methods: {
        async getAnnualReportsAndFinancialYears() {
            this.clientId = this.$store.state.topBar.selectedClient.value;
            this.annualReportsData = await Api.getAllAnnualReports(this.clientId);
            this.selectableFinancialYears = await Api.getAllFinancialYears(this.clientId);
        },
        showModalEdit(id) {
            this.selectedItemId = id;
            this.modalEditVisible = true;
        },
        showModalAdd() {
            this.modalAddVisible = true;
            this.$nextTick(() => {
                this.$refs.modalAdd.focusYearSelect();
            });
        },
        showConfirmDeleteModal(id) {
            this.annualReportId = id;
            this.modalDeleteVisible = true;
            this.$nextTick(() => {
                this.$refs.cancelSelect.$el.focus();
            });
        },
        async handleDelete() {
            this.$waiting.start("loading");
            this.modalDeleteVisible = false;
            await Api.deleteAnnualReport(this.annualReportId);
            await this.getAnnualReportsAndFinancialYears();
            this.$waiting.end("loading");
            this.$notify.success({ title: "Årsredovisning raderad" });
        },
        closeModalDelete() {
            this.modalDeleteVisible = false;
        },
    },
};
</script>
